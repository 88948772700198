<template>
  <label>
    <div class="checkbox-wrapper">
      <input :value="value" type="checkbox" @input="handleInput">
      {{ message }}
    </div>
  </label>
</template>

<script>
import i18n from 'i18n'

export default {
  name: 'MCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: i18n.t('confirm.understand')
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', e.target.checked)
    }
  }
}
</script>

<style lang="stylus" scoped>

label
  margin 12px 0 24px
  font-size 80%

.checkbox-wrapper
  display flex
  padding 4px 12px
  justify-content center

input[type=checkbox]
  appearance checkbox
  margin-inline-end 8px
  width 15px
  height 15px
  margin-top 2.5px
  accent-color var(--q-color-primary)
</style>
